import { Footer } from '@/src/pages/Home/components/Footer';
import styles from './Final.module.scss';
import { Header } from '@/src/pages/Home/components/Header';
import { Container } from '../Container';
import { useAppSelector } from '@/src/hooks';
import {
  currentGameTypeSelector,
  currentNumberCorrectAnswersSelector,
} from '@/src/store/selectors/game';
import { GAME_TYPE } from '@/src/constants';
import { RESULTS } from './data';
// import sendEventToCounters from '@/src/counterEvents';
import { VideoBg } from '../VideoBg';
import cn from 'classnames';
import { usePageContext } from '../App/PageContext';
import { noop } from '@/src/utils/noop';
import { HOME_SCREEN_TYPE } from '@/src/constants/home';
import { setHomeScreenType } from '@/src/store/slices/homeSlice';
import { useDispatch } from 'react-redux';
import { resetNumberCorrectAnswers } from '@/src/store/slices/gameSlice';
import sendEventToCounters from '@/src/counterEvents';

const getResultVariant = (type: string, count: number) => {
  if (type === GAME_TYPE.SING) {
    return RESULTS['forSing'];
  }
  if (count >= 5) {
    return RESULTS['excellent'];
  }
  if (count >= 2 && count <= 4) {
    return RESULTS['satisfactory'];
  }
  return RESULTS['badly'];
};

export const Final = () => {
  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;

  const dispatch = useDispatch();

  const gameType = useAppSelector(currentGameTypeSelector) || '';
  const numberCorrectAnswers = useAppSelector(
    currentNumberCorrectAnswersSelector,
  );

  const resultVariant = getResultVariant(gameType, numberCorrectAnswers);

  let action = '';

  switch (true) {
    case gameType == GAME_TYPE.GUESS_SONG:
      action = 'melody';
      break;
    case gameType == GAME_TYPE.GUESS_WORD:
      action = 'word';
      break;
    case gameType == GAME_TYPE.SING:
      action = 'singResult';
      break;
  }

  const sendAnalityc = () => {
    sendEventToCounters({
      action,
      // label: 'vacancies',
      label: 'vote',
    });
  };

  const handleHeaderMoreButtonClick = () => {
    dispatch(setHomeScreenType(HOME_SCREEN_TYPE.GAME_SELECTION));
    dispatch(resetNumberCorrectAnswers());
    changePage('home');
  };

  return (
    <>
      <div className={styles.final}>
        <Header
          isFinal={true}
          finalAction={action}
          onMoreButtonClick={handleHeaderMoreButtonClick}
        />
        <VideoBg className={styles.final__videobg} />
        <div className={styles.final__wrapper}>
          <Container className={styles.final__container}>
            <div
              className={cn(styles.final__text, {
                ['excellent']: resultVariant.type === 'excellent',
                ['satisfactory']: resultVariant.type === 'satisfactory',
                ['badly']: resultVariant.type === 'badly',
                ['forSing']: resultVariant.type === 'forSing',
              })}
            >
              {resultVariant.type === 'excellent' && (
                <>
                  {resultVariant.text}{' '}
                  <span className={styles.final__mark}>
                    {resultVariant.markedText}
                  </span>
                </>
              )}

              {resultVariant.type !== 'excellent' && (
                <>
                  <span className={styles.final__mark}>
                    {resultVariant.markedText}
                  </span>{' '}
                  {resultVariant.text}
                </>
              )}
            </div>
            <div className={styles.final__appeal}>{resultVariant.appeal}</div>
            <a
              className={styles.final__button}
              href="https://rating.hh.ru/poll/?utm_source=sibur_hh&utm_medium=referral&utm_campaign=special_project"
              // href="https://hh.ru/employer/3809"
              target="_blank"
              rel="noreferrer"
              onClick={sendAnalityc}
              // onClick={() => {
              //   sendEventToCounters({
              //     action: 'footer',
              //     label: 'vacancies',
              //   });
              // }}
            >
              <span className={styles['final__button-text']}>
                {/* {gameType == GAME_TYPE.SING ? 'Вперед' : 'Присоединяйся'} */}
                Голосовать
              </span>
              <span className={styles['final__button-icon']}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    fill="#fff"
                    d="M9.283 27.625V11.092l8.978-9.3 1.872 1.485-1.679 7.815h11.593v5.521l-4.78 11.012H9.284Zm1.938-1.938h12.82l4.069-9.655V13.03H16.065l1.711-8.04-6.555 6.91v13.789Zm-8.17 1.938V11.092h6.232v1.937H4.99v12.659h4.294v1.937H3.051Z"
                  />
                </svg>
              </span>
            </a>
          </Container>
        </div>

        <Footer />
      </div>
    </>
  );
};
