export const RESULTS = {
  excellent: {
    markedText: 'качества, достойные победителя!',
    text: 'Ты не только быстрый, но и сообразительный –',
    // appeal: 'Нас ждут великие дела!',
    appeal: 'Поддержи СИБУР – помоги нам стать лучшим работодателем России!',
    type: 'excellent',
  },
  satisfactory: {
    markedText: 'Ты достойный соперник!',
    text: 'Ещё немного – и ты станешь настоящим профи!',
    // appeal: 'В СИБУРе тебя ждут великие дела!',
    appeal:
      'А пока проголосуй за СИБУР – помоги нам стать лучшим работодателем России!',
    type: 'satisfactory',
  },
  badly: {
    markedText: 'Не стоит огорчаться!',
    text: 'Теперь у тебя есть достойная цель для роста.',
    // appeal: 'В СИБУРе у каждого есть возможности проявить себя.',
    appeal:
      'Помоги СИБУРу достигнуть цели – стать лучшим работодателем России!',
    type: 'badly',
  },
  forSing: {
    markedText: 'С хорошей песней любое дело веселее!',
    text: '',
    // appeal: 'Узнай больше о команде и проектах СИБУРа.',
    appeal: 'Поддержи СИБУР – помоги нам стать лучшим работодателем России!',
    type: 'forSing',
  },
};
